import React from 'react'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'utils/getGatsbyImageProps'

const Gallery = ({ value = {} }) => {
  return value.items && <Grid gap={'5rem'} px={'5rem'} mb='15rem' templateColumns={['repeat(4, 1fr)', null]}>
    {value.items.map(image =>
      <GridItem key={image._key} gridColumn={'span 2'} pb={'1rem'}>
        <GatsbyImage image={getGatsbyImageData(image, { hotspot: image.hotspot, placeholder: 'dominantColor' })} alt={image.asset?.altText || '' } />
      </GridItem>
    )
  }</Grid>
}

export default Gallery
