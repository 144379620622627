import React from 'react'
import { Box, Text, Heading, Grid, GridItem } from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import ProductDetails from 'components/Blocks/ProductDetails'
import { getGatsbyImageData } from 'utils/getGatsbyImageProps'
import Gallery from 'components/Modules/Gallery'

const ProductPageInfo = ({ product, getMaterials }) => {
  return <React.Fragment>
    {/* Product Gallery */}
    <Gallery value={{ items: product.gallery || [] }} />
    {/*
      product.gallery && <Grid gap={'5rem'} px={'5rem'} mb='15rem' templateColumns={['repeat(4, 1fr)', null]}>
      {(product.gallery || []).map((image, idx) => {
        return <GridItem key={image._key} gridColumn={'span 2'} pb={'1rem'}>
          <GatsbyImage image={getGatsbyImageData(image, { hotspot: image.hotspot, placeholder: 'dominantColor' })} alt={image.altText || `product-gallery-${idx}`} />
        </GridItem>
      })}
      </Grid>
    */}
    <ProductDetails verticalImage={product.verticalImage} description={product.description || {}} productInfo={product.productInfo || {}} materials={!product.isCustomizable && getMaterials() || []} />
    {
      product.isCustomizable && getMaterials().length > 0 &&
      <Grid gap={'5rem'} px={'5rem'} mb='15rem' templateColumns={['repeat(3, 1fr)', null]}>
        <GridItem
          gridColumn={'span 3'}
          pb={'2rem'}
          borderColor={'black'}
          borderBottom={'1px solid'}
          align={'center'}
        >
          <Heading as='h3' size='xl' variant='caps'>Materials</Heading>
        </GridItem>
        {getMaterials().map(material => {
          const isUphostery = material.key.includes('upholstery')
          let image
          if (material.imageFormats?.image?.asset) image = material.imageFormats?.image
          if (material.imageFormats?.squareImage?.asset) image = material.imageFormats?.squareImage
          return (
            <GridItem
              as='figure'
              key={material._id}
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box sx={{
                borderRadius: isUphostery ? '50%' : 'none',
                width: isUphostery ? 'calc(100% - 100px)' : 'calc(100% - 40px)',
                height: isUphostery ? 'calc(100% - 60px)' : '100%',
                boxShadow: isUphostery ? '2px 2px 6px 2px rgb(0 0 0 / 70%)' : 'none',
                overflow: 'hidden',
              }}>
                { image?.asset ? <GatsbyImage image={getGatsbyImageData(image, { square: true , hotspot: image.hotspot, placeholder: 'dominantColor' })} alt={image.altText || 'material swatch'} /> : <img width='100%' height='auto' src='https://via.placeholder.com/800x450' alt='placeholder'/>
                }
              </Box>
              <Box as='figcaption'
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  width: '100%',
                  transform: 'rotate(-90deg) translate(100%, 0)',
                  transformOrigin: 'right bottom'
                }}
              >
                {material.title}
              </Box>
            </GridItem>
          )
        })}
        <Box>
          <Text></Text>
        </Box>
      </Grid>
    }
    </React.Fragment>
  }

  export default ProductPageInfo
