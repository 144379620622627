import React from "react";
import {Button as ThemeUIButton} from '@chakra-ui/react'
import styled from '@emotion/styled'
import PropTypes from "prop-types"

// styled('h1')((props) => props.theme.styles.h1),

const StyledButton = styled(ThemeUIButton)((justify, square) => ({
  width: square ? '32px' : 'inherit',
  height: square ? '32px' : 'inherit',
  justifyContent: justify && 'center'
}), ({ border }) => {
  return {
    borderRadius: border === 'full' && `100%` || border === 'rounded' && `20px`,
    border: border && `1px solid #000000`,
  }
})

const Button = ({border, children, ...props}) => <StyledButton variant='base' border={border} {...props}>{children}</StyledButton>

Button.propTypes = {
  border: PropTypes.oneOf(['full','rounded'])
};

Button.defaultProps = {};

export default Button