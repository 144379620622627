import React, { useState, Children, cloneElement } from 'react'
import { PortableText } from '@portabletext/react'
import { Flex, Grid, GridItem, Box, Heading, Text } from '@chakra-ui/react'
import useCollapse from 'react-collapsed'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'utils/getGatsbyImageProps'
import clientConfig from '../../../client-config'

const AccordionParent = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
    }}>
      {
        // @TODO: there seems to be some SSR warnings coming from this cloneElement
        Children.map(children, (child, index) =>
        cloneElement(child, {
          ...child,
          isActive: activeIndex === index,
          onSelect: () => setActiveIndex(activeIndex === index ? null : index),
        })
      )}
    </Box>
  )
}
const Collapse = ({
  isActive,
  onSelect,
  title,
  children,
}) => {
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isActive,
  })

  return (
    <Box>
      <Flex
        {...getToggleProps({
          onClick: onSelect,
        })}
        align={'center'}
        height={'8rem'}
        sx={{
          cursor: 'pointer',
          borderBottom: '1px solid black'
        }}
      >
        <Text textStyle='rimd' variant='case' aria-hidden="true" style={{ marginRight: '8px' }}>
          {isActive ? '↑' : '↓'}
        </Text>
        <Heading as='h3' size='md' variant='caps'>{title}</Heading>
      </Flex>
      <Box {...getCollapseProps()} textStyle='rimd'>
        {children}
      </Box>
    </Box>
  )
}

const ProductDetails = ({ verticalImage = {}, description, productInfo, materials }) => {
  return <Grid gap={'5rem'} px={'5rem'} mb='15rem' templateColumns={['repeat(4, 1fr)', null]}>
    <GridItem colSpan={2}>
      {
        verticalImage?.asset &&
        <GatsbyImage image={getGatsbyImageData(verticalImage, {
          layout: 'fullWidth',
          aspectRatio: '0.75',
          // @TODO: responsively set aspect ratio (wider screen = higher value)
          hotspot: verticalImage.hotspot,
          placeholder: 'dominantColor'
        })} alt={verticalImage.altText || ''} />
      }
    </GridItem>
    <GridItem colSpan={'1rem'}>
      {
      // @TODO: match this by namedTag `product_description` to Sanity Attribute
      /*<Box pb={'1rem'} sx={{ borderBottom: '1px solid black' }}>
        <Flex
          align={'center'}
          height={'8rem'}
        >
          <Heading as='h3' size='md' variant='caps'>{description.title}</Heading>
        </Flex>
        <Box textStyle='rimd'><PortableText value={description._rawDescription} {...clientConfig.sanity} /></Box>
      </Box>*/}
      <AccordionParent>
        {(productInfo?.groups || []).map(group =>
          <Collapse title={group.title} key={group._key}>
            <PortableText value={group._rawBody} {...clientConfig.sanity} />
          </Collapse>
        )}
        { materials.length > 0 ?
          <Collapse title={'Materials'}>
            <Grid gap={'1rem'} templateColumns={['repeat(2, 1fr)', null]}>
              {materials.map(material => {
                let image
                if (material.imageFormats?.image?.asset) image = material.imageFormats?.image
                if (material.imageFormats?.squareImage?.asset) image = material.imageFormats?.squareImage
                return (
                  <Box as='figure' key={material._id}>
                    { image && image.asset ?
                    <GatsbyImage image={getGatsbyImageData(image, { hotspot: image.hotspot, placeholder: 'dominantColor' })} alt={image.altText || ''} /> : <img width="100%" height="auto" src="https://via.placeholder.com/800x450" alt="placeholder"/>
                    }
                    <Box as='figcaption' sx={{ textAlign: 'center' }}>
                      {material.title}
                    </Box>
                  </Box>
                )
              })}
            </Grid>
          </Collapse> : <div/>
        }
      </AccordionParent>
    </GridItem>
  </Grid>
}

export default ProductDetails
