import React, { useCallback, useState, useEffect } from 'react'
import { Text, Flex, Grid, Box, Circle, chakra } from '@chakra-ui/react'
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import { getMainImage } from 'hooks/getMainImage'
import Button from 'components/library/Button'

const MotionDiv = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});

const dictonary = {
  belt_buckle: 'buckles',
  belt_leather: 'leather straps',
  belt_webbing: 'webbing',
  metal_finish: 'metal finish',
  upholstery: 'upholstery'
}

const ProductPageHeader = ({
  product,
  getTitle,
  getMaterials,
  config,
  hasVariants,
  selectedVariant,
  options,
  setOptions,
  setVariantGid
}) => {

  const [isScrolled, setScrolled] = useState(false)

  // only on initial render
  useEffect(() => {
    setTimeout(() => {
      setScrolled(true)
    }, 2600)
  }, [])

  const mainImage = getMainImage({ originalSrc: selectedVariant?.store?.previewImageUrl || product.store.previewImageUrl, alt: `${getTitle().title} — ${getTitle().subTitle}`, width: 4000, height: 2667 }, [selectedVariant])

  const renderOptions = useCallback(() => {

    const displayOptions = product.store.options.map(option => {
      const sanityAttribute = config.getAttribute(option.name.toLowerCase())
      // maybe get attribyte by title helper?
      if (sanityAttribute) {
        sanityAttribute.options = sanityAttribute.options.filter(sanityOption => option.values.includes(sanityOption?.title))
        return sanityAttribute
      }
      // fallback if not sanity attribute matched (convert to similar object structure to sanity)
      // thus the `sanityAttribute` naming
      return {
        title: option.name,
        options: option.values.map(value => ({
          title: value
        }))
      }
    })

    return <Flex
      ml={'5rem'}
      pb={'2rem'}
      textStyle={'rism'}
      align='left'
      lineHeight='loose'
      height='100%'
      justify='end'
      direction='column'
      top='0'
      position='absolute'
      zIndex='docked'
    >
      {displayOptions.map((sanityAttribute, index) => {
        // console.log('sanityAttribute: ', sanityAttribute, sanityAttribute.options[index])
        return <Box key={`option-${index}`} mt={'2rem'}>
          <Box borderBottom={'1px solid'} mb={'1rem'} textStyle={'rimd'}>{`${sanityAttribute.title}`}
            { /* selectedVariant && selectedVariant.store[`option${(index + 1)}`] */}
            { /*
              // this doesn't work yet b/c it isn't targeting the selectedVariant
              // we can search for the by option.title
              selectedVariant && sanityAttribute.options[index]?.displayTitle
            */}
          </Box>
          <Flex alignItems={'center'} justify={'flex-start'}>
            {sanityAttribute.options.map(option => {
            const isActive = options[index] === option.title
            let label = null
            let bg = 'gray.1'
            // @TODO: material should probably use an image
            if (option.key === 'color' || option.key === 'material') {
              bg = option.color?.hex
            }
            if (option.key === 'size') {
              label = option.size?.label
            }
            return <Button
              p={0}
              pr={'1rem'}
              key={option._id}
              onClick={() => {
                setVariantGid('')
                setOptions((prevState) => {
                  prevState[index] = option.title
                  return [...prevState]
                })
              }}
            >
              <Circle pr={0} size={'78px'} width={'78px'} bg={bg} border={isActive && `2px solid black`}>
                {label}
              </Circle>
            </Button>
          })}
          </Flex>
        </Box>
      })
      }</Flex>
  })

  const renderShownHere = useCallback(() => {
    const materials = getMaterials().map((material) => {
      const index = Object.keys(dictonary).indexOf(material.key)
      const values = Object.values(dictonary)
      const string = `${material.title} ${values[index]}`
      if (index > -1) {
        return <div key={material._id}>{string.charAt(0).toUpperCase() + string.substr(1).toLowerCase()}</div>
      }
      return false
    }).filter(Boolean)

    return materials.length > 0 &&
      <Flex
        ml={'5rem'}
        pb={'2rem'}
        textStyle={'rism'}
        align='left'
        lineHeight='loose'
        height='100%'
        justify='end'
        direction='column'
        top='0'
        position='absolute'
        zIndex='docked'
        sx={{
          'div': {
            paddingRight: 4,
            width: '100%',
            whiteSpace: 'nowrap'
          },
          'div:not(:last-child)': {
            borderBottom: '1px solid black'
          }
        }}
      >
        <Text as='div' variant='caps'>Shown Here</Text>
        {materials}
      </Flex>
  }, [])

  return <Grid gap={'5rem'} templateColumns={['repeat(4, 1fr)', null]} sx={{ position: 'relative', transition: 'all 1s' }}>
      <AnimatePresence>
        { isScrolled &&
          <MotionDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            sx={{
              gridColumn: 'span 1',
              alignItem: 'end'
            }}
          >
            {!hasVariants && renderShownHere()}
            {hasVariants && renderOptions()}
          </MotionDiv>
        }
      </AnimatePresence>
      <MotionDiv
        layout
        transition={{
          // type: 'spring',
          // damping: 15,
          // stiffness: 150,
          // velocity: 3
        }}
        sx={{
          // boxShadow: isScrolled ? 'rgb(0 0 0) -1px 1px 0px' : 'none',
          gridColumn: isScrolled ? '2/5' : '1/5'
        }}
      >
        {mainImage}
      </MotionDiv>
    </Grid>
}

export default ProductPageHeader
