import React from 'react'
import { Box, Text, Flex, Grid, chakra } from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { AddItemButton } from 'components/cart'


const MotionDiv = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});

const ProductPageActions = ({
  selectedVariant,
  leadTime
}) => {

  return <React.Fragment>
    {/* Add to Cart etc */}
    <MotionDiv layout>
      <Grid pt={'2rem'} px={'5rem'} gap={'5rem'} templateColumns={['repeat(3, 1fr)', null]}>
        <Box sx={{ gridColumn: 'span 4' }}>
          {selectedVariant && <Flex sx={{
              alignItems: 'center',
              height: '10rem',
              mb: '15rem',
              borderColor: 'black',
              borderBottom: '1px solid'
            }}>
              <Flex textStyle={'rism'} flex='1 1 33.33%' direction='column' lineHeight='short'>
                { leadTime && `Lead time ${leadTime?.title}` }<br/>
                <Text as='span' variant='case'>→ Shipping and Returns</Text>
              </Flex>
              <Flex textStyle={'rixl'} variant='number' flex='1 1 33.33%' justify='center'>
                {`$ ${selectedVariant.store.price}`}
              </Flex>
              <Flex flex='1 1 33.33%' justify='flex-end'>
                <AddItemButton
                  variantId={selectedVariant.store.gid}
                  customAttributes={Object.keys({}).map(key => ({
                    key: key,
                    value: key,
                }))}/>
              </Flex>
            </Flex>}
        </Box>
      </Grid>
    </MotionDiv>
  </React.Fragment>
}

export default ProductPageActions
