import React, { useEffect, useContext, useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import { Box, Text, Heading, Flex, Container } from '@chakra-ui/react'
import { LayoutGroup } from 'framer-motion'
import { useQueryParamString, getQueryParams } from 'react-use-query-param-string'
import Transition from 'components/layout/Transition'
import ProductPageHeader from 'components/product/ProductPageHeader'
import ProductPageInfo from 'components/product/ProductPageInfo'
import ProductPageActions from 'components/product/ProductPageActions'
import { ConfigContext } from 'layouts'
import { Subheader } from 'components/layout'

const selectedAttributes = [
  'metal_finish',
  'upholstery',
  'upholstery_trade_name',
  'belt_buckle',
  'belt_leather',
  'belt_webbing'
]

// how to use this array order as the order for `attributesKeyValue`

const ProductPage = ({ data }) => {

  const { product } = data
  const config = useContext(ConfigContext)
  // We use the config methods for attributes in Sanity Studio to control the named tags carried by a product
  const productAttributes = product.attributes.map(({ key, value }, position) => {
      const option = config.getOption(key, value)
      if (!option) {
        // return { _id: `material-missing-${position}`, title: `${key}:${value}` }
      }
      return option
    }).filter(Boolean)

  const getMaterials = useCallback(() => {
    return [...productAttributes.filter(item => selectedAttributes.includes(item.key))]
  }, [])

  const leadTime = productAttributes.find((attr) => attr.key === 'lead_time')

  const getCode = useCallback(() => {
    const code = productAttributes.find((attr) => attr.key === 'collection')
    if (code?.title) {
      return `[${code.title[0]}C]`
    }
    return ''
  }, [])

  const getTitle = useCallback(() => {
    let title = ''
    let subTitle = ''
    if (product.displayTitle?.title) {
      title = product.displayTitle.title
      subTitle = product.displayTitle.subTitle
    } else {
      if (product.store.title.includes('-')) {
        const splitTitle = product.store.title.split('-')
        title = splitTitle[0]
        subTitle = splitTitle[1]
      } else {
        title = product.store.title
      }
    }
    return { title, subTitle }
  }, [])

  // VARIANT + OPTION LOGIC
  const hasVariants = product.store.variants.length > 1
  // set state from query param
  const queryParams = getQueryParams()
  const [variantGid, setVariantGid] = useQueryParamString('id', queryParams.id || '')
  const [options, setOptions] = useState(['', '', '']) // state should always be 3 length array, one entry for each variant option possible
  let selectedVariant
  if (variantGid) {
    // if we have a variantGid in state or URL query string
    selectedVariant = product.store.variants.find(variant => variant.store.gid === `gid://shopify/ProductVariant/${variantGid}`)
  } else {
    // default to first variant
    selectedVariant = product.store.variants.find(variant => variant.store.option1 === options[0] && variant.store.option2 === options[1] && variant.store.option3 === options[2]) || product.store.variants[0]
  }

  // console.log('selectedVariant: ', product.store, selectedVariant)

  // update the query param when selectedVariant changes
  useEffect(() => {
    if (selectedVariant) {
      const id = selectedVariant.store.gid.replace('gid://shopify/ProductVariant/', '')
      setVariantGid(id)
    }
  }, [selectedVariant])

  // sets intial option
  useEffect(() => {
    if (selectedVariant) {
      setOptions([selectedVariant.store.option1, selectedVariant.store.option2, selectedVariant.store.option3])
    }
  }, [])

  return (
    <Transition>
      <Subheader
        leftComponent={<Text as='span' variant='caps' textStyle={'rixl'} sx={{ lineHeight: 'short', fontFeatureSettings: `'ss02' on, 'case' on` }}>{getCode()}</Text>}
        centerComponent={
          <Text textStyle='rimd'><Text as='span' variant='caps' sx={{ lineHeight: 'short' }}>{getTitle().title}</Text> — {getTitle().subTitle}</Text>
        }
        layout={['10%', '80%', '10%']}
        backToTop={'Buy'}
      />
      <Box mt={'5rem'} ml={'5rem'} position={'absolute'} zIndex={888}>
        <Text as='div' variant='caps' textStyle={'rixl'} sx={{ lineHeight: 'short', fontFeatureSettings: `'ss02' on, 'case' on` }}>{getCode()}</Text>
      </Box>
      <Flex width='100%' position='absolute' mt={'5rem'} lineHeight='short' zIndex='docked' direction='column' justify='center' textAlign='center'>
        <Container as='hgroup' centerContent>
          <Heading as='h1' size='xl' variant='caps' lineHeight='short'>{getTitle().title}</Heading>
          <Heading as='p' size='xl' lineHeight='short'>{getTitle().subTitle}</Heading>
        </Container>
      </Flex>
      <LayoutGroup>
        <ProductPageHeader
          product={product}
          getTitle={getTitle}
          getMaterials={getMaterials}
          productAttributes={productAttributes}
          hasVariants={hasVariants}
          selectedVariant={selectedVariant}
          setOptions={setOptions}
          setVariantGid={setVariantGid}
          options={options}
          config={config}
        />
        <ProductPageActions
          leadTime={leadTime}
          selectedVariant={selectedVariant}
        />
      </LayoutGroup>
      <ProductPageInfo product={product} getMaterials={getMaterials} />
    </Transition>
  )
}

export default ProductPage

export const query = graphql`
query($id: String!) {
  product: sanityProduct(id: { eq: $id }) {
    displayTitle {
      title
      subTitle
    }
    isCustomizable
    gallery {
      ...ImageWithHotspot
    }
    attributes {
      key
      value
    }
    productInfo {
      groups {
        title
        _rawBody
      }
    }
    verticalImage {
      ...ImageWithHotspot
    }
    store {
      title
      previewImageUrl
      productType
      options {
        name
        values
      }
      variants {
        store {
          previewImageUrl
          gid
          price
          sku
          title
          option2
          option1
          option3
        }
      }
    }
  }
}`
